import { CancelToken, HttpClient } from "./HttpClient";

export class TransactionApiClient extends HttpClient {
    constructor() {
        super('https://api.account-tracker.maisiesadler.co.uk')
    }
}

export const TransactionApi = {
    accounts: (cancelToken: CancelToken) =>
        new TransactionApiClient().Get<GetAccountsResponse>('/transactions/accounts', cancelToken),
    view: (accountId: string, cancelToken: CancelToken) =>
        new TransactionApiClient().Get<ViewTransactionsResponse>(`/transactions/view/${accountId}`, cancelToken),
    mapped: (accountId: string, cancelToken: CancelToken) =>
        new TransactionApiClient().Get<GetMappedTransactionsResponse>(`/view/${accountId}`, cancelToken),
    recalculateMappedGroup: (accountId: string, group: string) =>
        new TransactionApiClient().Get<GetMappedTransactionsResponse>(`/view/${accountId}/recalculate/${group}`),
    transactions: {
        updateCategory: (accountId: string, group: string, transactionId: string, category: string) =>
            new TransactionApiClient().Post<{ category: string }>(`/view/${accountId}/transactions/${group}/${transactionId}`, { category }),
        delete: (accountId: string, group: string, transactionId: string) =>
            new TransactionApiClient().Delete<void>(`/transactions/${accountId}/${group}/${transactionId}`),
        manual: (accountId: string, group: string, transaction: NewTransaction) =>
            new TransactionApiClient().Post<void>(`/transactions/manual/${accountId}/${group}`, transaction),
    },
    bank: {
        loginStatus: (cancelToken: CancelToken) =>
            new TransactionApiClient().Get<BankLoginStatus>('/transactions/bank/login', cancelToken),
        exchangeCode: (code: string, cancelToken?: CancelToken) =>
            new TransactionApiClient().Post<BankLoginStatus>('/transactions/bank/login', { code }, cancelToken),
        refresh: (cancelToken?: CancelToken) =>
            new TransactionApiClient().Get<BankLoginStatus>('/transactions/bank/refresh', cancelToken),
        updateTransactions: (group: string) =>
            new TransactionApiClient().Get<UpdateTransactionsResponse>(`/transactions/bank/transactions?date=${group}`),
    },
    rules: {
        get: (accountId: string, cancelToken: CancelToken) =>
            new TransactionApiClient().Get<{ rules: Rule[] }>(`/rules/${accountId}`, cancelToken),
        post: (accountId: string, rules: Rule[], cancelToken?: CancelToken) =>
            new TransactionApiClient().Post<{ rules: Rule[] }>(`/rules/${accountId}`, { rules }, cancelToken),
    },
}

export interface NewTransaction {
    timestamp: string
    description: string
    amount: number
    source: string
}

export interface Rule {
    type: 'source' | 'description';
    match: string;
    category: string;
}

export interface BankLoginStatus {
    auth_url: string
    access_token_expiry: string
}

export interface UpdateTransactionsResponse {
    results: {
        group: string;
        transactions: number;
        updated: number;
    }[]
}

export interface GetAccountsResponse {
    message: string
    results: { accounts: { id: string, name: string }[] }
}

export interface ViewTransactionsResponse {
    account_id: string
    transactions: { [key: string]: Transaction[] }
}

export interface Transaction {
    id: string
    timestamp: string
    description: string
    transactionType: string // CREDIT or DEBIT
    transactionCategory: string
    amount: number // credit transfers are +ve
    source: string
}

export interface GetMappedTransactionsResponse {
    // group
    [key: string]: {
        lastRecalculated: string
        transactions: TransactionsAndCategory[]
        // category: total
        totals: { [key: string]: number }
    }
}

export interface TransactionsAndCategory {
    transaction: Transaction
    category: string
}
