import React from 'react';
import './App.css';
import { Layout, Menu, MenuProps, Breadcrumb } from 'antd';
import { TransactionApi } from './api/TransactionApi';
import { CancelToken } from './api/HttpClient';
import { SideMenu, SelectedMenuItem } from './SideMenuDefinition';
import { RoutePageComponent } from './RoutePage';
import { logger } from './utils';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export interface AppComponentProps {
}

export interface AppComponentState {
  accounts: { id: string, name: string }[] | undefined
  selectedMenuItem: SelectedMenuItem
}

export class App extends React.Component<AppComponentProps, AppComponentState> {

  constructor(props: AppComponentProps) {
    super(props)
    this.state = {
      accounts: [],
      selectedMenuItem: {
        breadcrumb: ['Home']
      }
    };

    this.cancelToken = new CancelToken();
  }

  private readonly cancelToken: CancelToken
  private _isCanceled: boolean = false

  componentDidMount() {
    TransactionApi.accounts(this.cancelToken)
      .then(response => {
        this.setState({
          accounts: response.data?.results.accounts
        });
      })
      .catch(err => {
        if (!this._isCanceled) {
          logger.warn('get accounts cancelled', err);
        }
      });
  }

  componentWillUnmount() {
    this._isCanceled = true;
    this.cancelToken.cancel();
  }

  render() {
    const { accounts, selectedMenuItem } = this.state
    const { menu, getMenuItem } = new SideMenu().buildMenu(accounts || [])

    const navMenuItems: Required<MenuProps>['items'] = menu.map((item, index) => ({
      label: item.title,
      key: index,
      children: item.menuitems?.map((menuItem, menuIdx) => ({
        label: menuItem.title,
        key: `${index}-${menuIdx}`
      }))
    }))

    return <Layout>
      <Header className="header">
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[]}
          items={[{
            label: 'nav 1', key: '1'
          }]}
        />
      </Header>
      <Layout>
        <Sider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            defaultSelectedKeys={['0']}
            defaultOpenKeys={[]}
            style={{ height: '100%', borderRight: 0 }}
            onSelect={(e) => this.setState({ selectedMenuItem: getMenuItem(e.key) })}
            items={navMenuItems}
          />
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Breadcrumb
            style={{ margin: '16px 0' }}
            items={selectedMenuItem.breadcrumb.map(title => ({ title }))}>
          </Breadcrumb>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <RoutePageComponent selectedMenuItem={selectedMenuItem} />
          </Content>
        </Layout>
      </Layout>
    </Layout >
  }
}

export default App;