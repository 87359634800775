import React from 'react';
import { Button } from 'antd';
import { CancelToken } from '../api/HttpClient';
import { BankLoginStatus, TransactionApi } from '../api/TransactionApi';
import { logger } from '../utils';

export interface TrueLayerStatusComponentProps {
}

export interface TrueLayerStatusComponentState {
    loginStatus?: BankLoginStatus
    error?: string
    exchangingCode?: string
    updatingTransactions: boolean
    attemptedRefreshError?: string
    isRefreshing: boolean
}

export class TrueLayerStatusComponent extends React.Component<TrueLayerStatusComponentProps, TrueLayerStatusComponentState> {
    constructor(props: TrueLayerStatusComponentProps) {
        super(props)
        this.state = {
            updatingTransactions: false,
            isRefreshing: false,
        };

        this.loadLoginStatus()
    }

    private cancelToken?: CancelToken
    private _isCanceled: boolean = false

    loadLoginStatus() {
        this.cancelToken = new CancelToken();
        this._isCanceled = false
        TransactionApi.bank.loginStatus(this.cancelToken)
            .then(response => {
                if (response.statusCode === 200) {
                    this.setState({
                        loginStatus: response.data
                    });
                } else {
                    this.setState({ error: (response.data as any).message || ':(' })
                }
            })
            .catch(err => {
                if (!this._isCanceled) {
                    logger.warn('get transactions cancelled', err);
                }
            });
    }

    refresh() {
        const { isRefreshing } = this.state
        if (isRefreshing) return
        this.setState({ isRefreshing: true })
        TransactionApi.bank.refresh()
            .then(response => {
                if (response.statusCode === 200) {
                    window.location.href = '/'
                } else {
                    this.setState({ isRefreshing: false, attemptedRefreshError: (response.data as any).error_code || ':(' })
                }
            })
            .catch(err => {
                logger.error('exchangeCode error', err);
            });
    }

    exchangeCode(code: string) {
        const { exchangingCode } = this.state
        if (code === exchangingCode) return
        this.setState({ exchangingCode: code })
        TransactionApi.bank.exchangeCode(code)
            .then(response => {
                if (response.statusCode === 200) {
                    window.location.href = '/'
                } else {
                    this.setState({ error: (response.data as any).message || ':(' })
                }
            })
            .catch(err => {
                logger.error('exchangeCode error', err);
            });
    }

    // todo: delete me?
    updateTransactions() {
        TransactionApi.bank.updateTransactions('2024-11')
            .then(response => {
                this.setState({ updatingTransactions: false })
            })
            .catch(err => {
                logger.error('updateTransactions error', err);
                this.setState({ updatingTransactions: false })
            });
        this.setState({ updatingTransactions: true })
    }

    componentWillUnmount() {
        this._isCanceled = true;
        this.cancelToken?.cancel();
    }

    render() {
        const { loginStatus, error, updatingTransactions, attemptedRefreshError, isRefreshing } = this.state

        if (error) {
            return <>Error: {error}</>
        }

        if (!loginStatus) {
            return <>Waiting</>
        }

        const queryString = window.location.href.split('?')[1]
        if (!!queryString) {
            const params: { [key: string]: string } = {}
            queryString.split('&').forEach(kv => {
                const [key, value] = kv.split('=')
                params[key] = value
            })
            const code = params['tl_code']
            this.exchangeCode(code)
        }

        const hasAccessToken = !!loginStatus.access_token_expiry
        let accessTokenExpired = true
        let accesstoken = ''

        if (hasAccessToken) {
            const accessTokenDate = new Date(loginStatus.access_token_expiry)
            accessTokenExpired = new Date().getTime() - accessTokenDate.getTime() > 0
            accesstoken = accessTokenDate.toLocaleString()
        }

        return <>
            {hasAccessToken && <div>
                {accessTokenExpired && <>
                    <div>Access token expired at <b>{accesstoken}</b></div>
                    {!attemptedRefreshError && <>
                        {isRefreshing && <div>waiting</div>}
                        {!isRefreshing && <Button onClick={() => this.refresh()}>Refresh Token</Button>}
                    </>}
                    {!!attemptedRefreshError && <>
                        <div>Could not refresh: {attemptedRefreshError}</div>
                        <Button href={loginStatus.auth_url}>Update access token</Button>
                    </>}
                </>}
                {!accessTokenExpired && <>
                    <div>Access token expires at <b>{accesstoken}</b></div>
                    {!updatingTransactions && <Button onClick={() => this.updateTransactions()}>Update transactions</Button>}
                    {updatingTransactions && <Button disabled={true}>Updating transactions</Button>}
                </>}
            </div>}
            {!hasAccessToken && <div>no access token found</div>}
        </>
    }
}
