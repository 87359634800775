import { PlusOutlined } from '@ant-design/icons';
import { Select, Divider, Input, Table, Button, Tooltip, Card, Row, Col, DatePicker, InputNumber, Form } from 'antd';
import { ColumnFilterItem } from 'antd/lib/table/interface';
// import { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import { CancelToken } from '../api/HttpClient';
import { GetMappedTransactionsResponse, NewTransaction, TransactionApi, TransactionsAndCategory } from '../api/TransactionApi';
import { logger } from '../utils';

export interface CategorisedTransactionsComponentProps {
    accountId: string
}

interface CategoryTotal {
    category: string
    total: number
}

function toFriendlyDate(dateIsoString: string): string {
    const date = new Date(dateIsoString)
    return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`
}

export interface CategorisedTransactionsComponentState {
    categories: string[]
    totals: CategoryTotal[]
    response?: GetMappedTransactionsResponse | undefined
    loadedAccountId?: string
    error?: string
    editing: boolean
    showMappingValue: boolean
    recalulating: boolean
    mappingValue: string
}

const CategorySummaryTable = (params: { totals: CategoryTotal[] }) => {

    return <div>
        {params.totals.map(({ category, total }) =>
            <div key={category}>
                <span><b>{category}</b> = {total}</span>
            </div>
        )}
    </div>
}

const CategorySummarySection = ({ totals }: { totals: CategoryTotal[] }) => {

    let minTotal = 10000000
    totals.forEach(({ total }) => {
        if (total < minTotal) minTotal = total
    })

    const diffs = totals.map(({ category, total }) => {
        let diff = total - minTotal
        diff = Math.round(diff * 100) / 100
        return { category, total: diff }
    })

    return <Row gutter={16}>
        <Col span={8}>
            <Card title="Totals" size="small" style={{ width: 300 }}>
                <CategorySummaryTable totals={totals} />
            </Card>
        </Col>

        <Col span={8}>
            <Card title="Diffs" size="small" style={{ width: 300 }}>
                <CategorySummaryTable totals={diffs} />
            </Card>
        </Col>
    </Row>
}

const TransactionsTable = ({ transactions, editing, categories, onUpdateCategory }: {
    transactions: TransactionsAndCategory[],
    editing: boolean,
    categories: string[],
    onUpdateCategory: (transaction: TransactionsAndCategory, category: string) => void,
}) => {
    const data = transactions.map(t => ({
        date: toFriendlyDate(t.transaction.timestamp),
        ...t
    }))
    return <Table rowKey={t => t.transaction.id} dataSource={data}
        expandable={{
            expandedRowRender: row =>
                <>
                    <div><b>Description</b>: {row.transaction.description}</div>
                    <div><b>Source</b>: {row.transaction.source}</div>
                    {editing && <>
                        <Divider />
                        <SelectCategoryComponent defaultValue={row.category} categories={categories} onSelectCategory={category => onUpdateCategory(row, category)} />
                    </>}
                </>
            ,
            rowExpandable: record => true
        }}
    >
        <Table.Column title="Timestamp" dataIndex={['date']} key="timestamp" />
        <Table.Column
            title="Category"
            dataIndex={['category']}
            key="category"
            filters={getCategories(transactions)}
            onFilter={(value, record: TransactionsAndCategory) => record.category === value}
        />
        <Table.Column title="Amount" dataIndex={['transaction', 'amount']} key="amount" />
    </Table>
}

function getCategories(transactionsAndCategories: TransactionsAndCategory[]): ColumnFilterItem[] {
    const categories: { [key: string]: boolean } = {}
    transactionsAndCategories.forEach(({ category }) => categories[category] = true)

    return Object.keys(categories).map(category => ({ text: category, value: category }))
}

const SelectCategoryComponent = ({ defaultValue, categories, onSelectCategory }
    : { defaultValue: string, categories: string[], onSelectCategory: (category: string) => void }) => {
    const [categoryInputValue, updateCategoryInputValue] = useState('')
    const [localCategories, updateLocalCategories] = useState(categories)

    const addCurrentCategory = () => {
        if (!categoryInputValue) return
        let found = false
        localCategories.forEach(c => { if (c === categoryInputValue) found = true })
        if (found) return
        updateLocalCategories([...localCategories, categoryInputValue])
        updateCategoryInputValue('')
    }

    return <div>
        <span style={{ marginRight: 10 }}>Update category</span>
        <Select
            style={{ width: 240 }}
            placeholder="Select Category"
            defaultValue={defaultValue}
            onSelect={(value: string) => onSelectCategory(value)}
            dropdownRender={menu => (
                <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input.Group>
                            <Input style={{ width: 'calc(100% - 36px)' }} placeholder='New Category' value={categoryInputValue} onChange={ev => updateCategoryInputValue(ev.target.value)} />
                            <Tooltip title="Add item">
                                <Button icon={<PlusOutlined />} onClick={() => addCurrentCategory()} />
                            </Tooltip>
                        </Input.Group>
                    </div>
                </div>
            )}
        >
            {localCategories && localCategories.map(item => (
                <Select.Option key={item} value={item}>{item}</Select.Option>
            ))}
        </Select>
    </div>
}

const AddNewTransactionComponent = ({ onAddNewTransaction }
    : { onAddNewTransaction: (newTransaction: NewTransaction) => void }) => {

    return <Form layout="horizontal"
        onFinish={({ description, amount, source, timestamp }) => onAddNewTransaction({ description, amount, source, timestamp: timestamp.toDate().toISOString() })}
        labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
        <Form.Item label="Date" name="timestamp" rules={[{ required: true, message: 'Date is required' }]}>
            <DatePicker
                format="YYYY-MM-DD HH:mm:ss"
            // showTime={{ defaultValue: ) }}
            />
        </Form.Item>
        <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Description is required' }]}>
            <Input placeholder="Description of transaction" />
        </Form.Item>
        <Form.Item label="Amount" name="amount" rules={[{ required: true, message: 'Amount is required' }]}>
            <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="Source" name="source" rules={[{ required: true, message: 'Source is required' }]}>
            <Input placeholder="Source of the transaction" />
        </Form.Item>
        <Form.Item name="submit" wrapperCol={{ span: 12, offset: 6 }}>
            <Button type="primary" htmlType="submit">Add</Button>
        </Form.Item>
    </Form>
}

export class CategorisedTransactionsComponent extends React.Component<CategorisedTransactionsComponentProps, CategorisedTransactionsComponentState> {
    constructor(props: CategorisedTransactionsComponentProps) {
        super(props)
        const now = new Date()
        this.state = {
            editing: false,
            categories: [],
            totals: [],
            recalulating: false,
            showMappingValue: false,
            mappingValue: now.getFullYear() + '-' + ((now.getMonth() + 1) + '').padStart(2, '0'),
        };
    }

    private cancelToken?: CancelToken
    private _isCanceled: boolean = false

    calculateCategories(response: GetMappedTransactionsResponse | undefined): string[] {
        if (!response) return []
        const categories: { [key: string]: boolean } = {}
        Object.keys(response)
            .forEach(group => Object.keys(response[group].totals)
                .forEach(category => categories[category] = true))
        const hasVoid = !!categories['void']
        delete categories['void']

        const keys = Object.keys(categories)
        if (hasVoid) keys.push('void')
        return keys
    }

    calculateTotals(response: GetMappedTransactionsResponse | undefined): CategoryTotal[] {
        if (!response) return []
        const categories: { [key: string]: number } = {}
        Object.keys(response)
            .forEach(group => Object.keys(response[group].totals)
                .forEach(category => {
                    if (category === 'void') return
                    if (category === 'interest') return
                    if (!categories[category]) categories[category] = 0

                    categories[category] += response[group].totals[category]
                    categories[category] = Math.round(categories[category] * 100) / 100
                }))

        return Object.keys(categories)
            .map(category => ({ category, total: categories[category] }))
    }

    loadAccountTransactions(accountId: string) {
        this.cancelToken = new CancelToken();
        this._isCanceled = false
        TransactionApi.mapped(accountId, this.cancelToken)
            .then(response => {
                if (response.statusCode === 200) {
                    const categories = this.calculateCategories(response.data)
                    const totals = this.calculateTotals(response.data)
                    this.setState({
                        recalulating: false,
                        categories,
                        totals,
                        response: response.data,
                        loadedAccountId: accountId,
                    });
                } else {
                    this.setState({ error: (response.data as any).message || ':(' })
                }
            })
            .catch(err => {
                if (!this._isCanceled) {
                    logger.warn('get transactions cancelled', err);
                }
            });
    }

    addCategory(category: string) {
        const { categories } = this.state
        if (!categories) return
        let found = false
        categories.forEach(c => { if (c === category) { found = true } })
        if (found) return
        categories.push(category)
        this.setState({ categories })
    }

    updateCategory(transactionsAndCategory: TransactionsAndCategory, group: string, category: string) {
        const { accountId } = this.props
        logger.info('updating', transactionsAndCategory, group, category)
        TransactionApi.transactions.updateCategory(accountId, group, transactionsAndCategory.transaction.id, category)
            .then(response => {
                if (response.statusCode === 200) {
                    logger.info('updateCategory done, recalculating ' + group)
                    this.recalculateMappedGroup(group)
                } else {
                    this.setState({ error: (response.data as any).message || ':(' })
                }
            })
            .catch(err => {
                if (!this._isCanceled) {
                    logger.warn('get transactions cancelled', err);
                }
            });
    }

    addManualTransaction(newTransaction: NewTransaction) {
        const { accountId } = this.props
        const group = newTransaction.timestamp.substring(0, 7)
        logger.info('updating', group, newTransaction)
        TransactionApi.transactions.manual(accountId, group, newTransaction)
            .then(response => {
                if (response.statusCode === 200) {
                    logger.info('manual added, recalculating ' + group)
                    this.recalculateMappedGroup(group)
                    this.setState({ editing: false })
                } else {
                    this.setState({ editing: false, error: (response.data as any).message || ':(' })
                }
            })
            .catch(err => {
                if (!this._isCanceled) {
                    logger.warn('add manual cancelled', err);
                }
            });
    }

    recalculateMappedGroup(group: string) {
        const { accountId } = this.props
        this.setState({ recalulating: true })
        console.log("recalculating", group)
        TransactionApi.bank.updateTransactions(group)
            .then(response => {
                logger.info('got latest transactions', response);
            })
            .catch(err => {
                logger.error('updateTransactions error', err);
            })
            .finally(() => {
                logger.info('try remapping group', group);
                TransactionApi.recalculateMappedGroup(accountId, group).then(response => {
                    if (response.statusCode === 200) {
                        logger.info('recalculateMappedGroup done, reloading', response)
                        this.loadAccountTransactions(accountId)
                    } else {
                        this.setState({ recalulating: false, error: (response.data as any).message || ':(' })
                    }
                })
                    .catch(err => {
                        if (!this._isCanceled) {
                            logger.warn('get transactions cancelled', err);
                        }
                    });
            });
    }

    componentWillUnmount() {
        this._isCanceled = true;
        this.cancelToken?.cancel();
    }

    setMappingValue(value: string) {
        this.setState({ mappingValue: value })
    }

    render() {
        const { accountId } = this.props
        const {
            categories, response, loadedAccountId, error, editing, totals,
            mappingValue, showMappingValue, recalulating } = this.state

        if (error) {
            return <>Error: {error}</>
        }

        if (loadedAccountId !== accountId || !response) {
            this.loadAccountTransactions(accountId)
            return <>Waiting</>
        }

        interface TableData {
            group: string,
            lastRecalculated: string
            transactions: TransactionsAndCategory[]
            totals: { [key: string]: number },
            [otherOptions: string]: unknown;
        }

        const data: TableData[] = Object.keys(response)
            .reverse()
            .map(group => ({ group, ...response[group] }))

        data.forEach(d => {
            d.friendlyDate = toFriendlyDate(d.lastRecalculated)
        })

        return <>
            <Row gutter={24}>
                <Col span={16}>
                    <CategorySummarySection totals={totals} />
                    <Divider />
                    <Button onClick={() => this.setState({ editing: !editing })}>toggle edit state</Button>
                    <br />
                    <Button disabled={recalulating} onClick={() => this.recalculateMappedGroup(mappingValue)}>recalculate mappings for {mappingValue}</Button>
                    <Button onClick={() => this.setState({ showMappingValue: !showMappingValue })}>edit</Button>
                    {showMappingValue && <Input style={{ width: '20%' }} value={mappingValue} onChange={val => {
                        val.preventDefault()
                        const value = (val.target as any).value
                        this.setMappingValue(value)
                    }} />}
                    {editing && <>
                        <Divider />
                        <Row gutter={16}>
                            <Col span={8}>
                                <Card title="Add new transaction" size="small" style={{ width: 500 }}>
                                    <AddNewTransactionComponent onAddNewTransaction={transaction => this.addManualTransaction(transaction)} />
                                </Card>
                            </Col>
                        </Row>
                    </>}
                </Col>
                <Col span={8}>
                    <img src="file-cabinet.jpg" style={{ maxWidth: "200px", float: 'right' }} alt="" />
                </Col>
            </Row>
            <Divider />
            <Table rowKey={t => t.group} dataSource={data}
                expandable={{
                    expandedRowRender: row =>
                        <>
                            <TransactionsTable
                                categories={categories}
                                editing={editing}
                                transactions={row.transactions}
                                onUpdateCategory={(transaction, category) => this.updateCategory(transaction, row.group, category)}
                            />
                            <Button onClick={() => this.recalculateMappedGroup(row.group)}>recalculate mappings for {row.group}</Button>
                        </>
                    ,
                    rowExpandable: record => true
                }}
            >
                <Table.Column title="Group" dataIndex={['group']} key="group" />
                <Table.Column title="Totals" key="totals" render={(tableDataRow: TableData) => <>
                    {categories.map(c => !!tableDataRow.totals[c] && <div key={c}><b>{c}</b>: {tableDataRow.totals[c]}</div>)}
                </>} />
                <Table.Column title="Last Recalculated" dataIndex={['friendlyDate']} key="lastRecalculated" />
            </Table>
        </>
    }
}
